export enum Language {
  JA = 'ja',
  EN = 'en',
}

export enum AILanguage {
  JA = 'ja',
  EN = 'en',
  OTHER = 'other',
}

// LoginUserMembership.uifFlagsにおける
// メッセージ投稿時におけるショートカット設定の定数
// ctrl_enter: Ctrl + Enterで投稿、Enterで改行
// enter     : Enterで投稿、Ctrl + Enterで改行
// TODO: 書き方が微妙なのでUI_FLAGS周りは全体的に整理・再設計が必要
export const UI_FLAG_QP_POST_SHORTCUT_TYPE = "POST_SHORTCUT_TYPE";
export const UI_FLAG_QP_POST_SHORTCUT_TYPE_VAL_CTRL_ENTER = "ctrl_enter";
export const UI_FLAG_QP_POST_SHORTCUT_TYPE_VAL_ENTER = "enter";
export type UI_FLAG_QP_POST_SHORTCUT_TYPE_VAL = (
  | typeof UI_FLAG_QP_POST_SHORTCUT_TYPE_VAL_CTRL_ENTER
  | typeof UI_FLAG_QP_POST_SHORTCUT_TYPE_VAL_ENTER
);
export const UI_FLAG_QP_POST_SHORTCUT_TYPE_VALS = [
  UI_FLAG_QP_POST_SHORTCUT_TYPE_VAL_CTRL_ENTER,
  UI_FLAG_QP_POST_SHORTCUT_TYPE_VAL_ENTER,
] as const;

