import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import { RootState, useAppDispatch } from '@/store';
import AdminPageTitle from '../../admin/components/AdminPageTitle';
import { AdminContentsWrapper } from '../../admin/components/AdminUI';
import { useTranslation } from 'react-i18next';
import { z } from '@/lib/zod';
import { useForm, Controller } from "react-hook-form";
import { zodResolver } from "@hookform/resolvers/zod";
import {
  Button,
  FormControl,
  Box,
  FormLabel,
  Divider,
  Typography,
  FormControlLabel,
  Radio,
  RadioGroup,
  CircularProgress,
} from '@mui/material';
import { useToast } from '../../generic/hooks/useToast';
import { captureException } from '@sentry/react';
import { useErrorModal } from '../../generic/hooks/useErrorModal';
import { getUiFlagValue } from '../../auth/auth.type';
import { updateUiMembershipFlag } from '../../auth/auth.slice';
import { UI_FLAG_QP_POST_SHORTCUT_TYPE, UI_FLAG_QP_POST_SHORTCUT_TYPE_VAL, UI_FLAG_QP_POST_SHORTCUT_TYPE_VAL_CTRL_ENTER, UI_FLAG_QP_POST_SHORTCUT_TYPE_VALS } from '../setting.constant';

const inputSchema = z.object({
  postShortcutType: z.enum(UI_FLAG_QP_POST_SHORTCUT_TYPE_VALS),
});

export type Input = z.infer<typeof inputSchema>;

const ControlSetting: React.FC = () => {
  const dispatch = useAppDispatch();
  const { t } = useTranslation();
  const loginUser = useSelector((state: RootState) => state.auth.loginUser);
  const { showToast } = useToast();
  const [isLoading, setIsLoading] = useState(false);
  const { setUnknownErrorModal } = useErrorModal();

  // デフォルトのショートカットタイプをUIフラグから取得
  const defaultShortcut = getUiFlagValue(
    loginUser,
    UI_FLAG_QP_POST_SHORTCUT_TYPE,
    UI_FLAG_QP_POST_SHORTCUT_TYPE_VAL_CTRL_ENTER
  ) as UI_FLAG_QP_POST_SHORTCUT_TYPE_VAL;

  // useFormで管理
  const { handleSubmit, control } = useForm<Input>({
    resolver: zodResolver(inputSchema),
    defaultValues: {
      postShortcutType: defaultShortcut,
    },
  });

  if (!loginUser) {
    return null;
  }

  // 保存ボタン押下時の処理
  const onSubmit = async (data: Input) => {
    setIsLoading(true);
    try {
      // UIフラグを更新
      await dispatch(
        updateUiMembershipFlag({
          key: UI_FLAG_QP_POST_SHORTCUT_TYPE,
          value: data.postShortcutType,
        })
      ).unwrap();

      // t:操作設定を更新しました。
      showToast(t("setting:control.message.updateSuccess"), "success");
    } catch (err) {
      captureException(err);
      setUnknownErrorModal();
      console.error("updateUiMembershipFlag error", err);
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <>
      {/* t:操作設定 */}
      <AdminPageTitle title={t("setting:control.pageTitle")} />
      <Divider />
      <AdminContentsWrapper>
        <Typography variant="body2" color="textSecondary" style={{ marginBottom: '25px' }}>
          {/* t:UIの操作に関する設定ができます。 */}
          {t("setting:control.pageDescription")}
        </Typography>

        <form onSubmit={handleSubmit(onSubmit)} style={{ width: "100%" }}>
          <Box mb={4}>
            <FormControl>
              {/* t:メッセージ投稿のショートカット */}
              <FormLabel id="post-shortcut-type-label">{t("setting:control.column.postShortcutType")}</FormLabel>
              <Controller
                name="postShortcutType"
                control={control}
                render={({ field }) => (
                  <RadioGroup
                    aria-labelledby="post-shortcut-type-label"
                    {...field}
                    value={field.value}
                    onChange={(e) => field.onChange(e.target.value)}
                  >
                    <FormControlLabel
                      value="ctrl_enter"
                      control={<Radio />}
                      // t:Control+Enterで投稿、Enterで改行
                      label={<Typography variant="body2">{t("setting:control.column.ctrlEnter")}</Typography>}
                    />
                    <FormControlLabel
                      value="enter"
                      control={<Radio />}
                      // t:Enterで投稿、Control+Enterで改行
                      label={<Typography variant="body2">{t("setting:control.column.enter")}</Typography>}
                    />
                  </RadioGroup>
                )}
              />
            </FormControl>
          </Box>

          <Button
            type="submit"
            variant="contained"
            color="primary"
            disabled={isLoading}
            startIcon={isLoading ? <CircularProgress size={24} /> : null}
          >
            {/* t:保存 */}
            {t("common:button.save")}
          </Button>
        </form>
      </AdminContentsWrapper>
    </>
  );
};

export default ControlSetting;
