import React from 'react';
import { NavLink } from 'react-router-dom';
import { List, ListItemButton, ListItemIcon, ListItemText} from '@mui/material';
import { ControlCamera, Language, Person } from '@mui/icons-material';
import styled from '@emotion/styled';
import { useSelector } from 'react-redux';
import { RootState } from '@/store';
import { useTranslation } from 'react-i18next';

// TODO AdminLeftSidebarの内容とかなり重複しているのでもっとDRYにする

const StyledNavLink = styled(NavLink)`
  text-decoration: none;
  color: inherit;

  &.active {
    background-color: rgba(0, 0, 0, 0.08);
  }
`;
const StyledListItemButton = styled(ListItemButton)`
  padding-left: 16px;
`;

const StyledListItemIcon = styled(ListItemIcon)`
  min-width: 40px;
`;

const StyledListItemText = styled(ListItemText)`
  margin: 0;
  & .MuiListItemText-primary {
    font-size: 0.875rem;
  }
`;

const ListItem: React.FC<{ name: string, linkTo: string, icon: React.ReactNode, onItemClick: () => void }> = ({ name, linkTo, icon, onItemClick }) => {
  return (
    <>
      <StyledNavLink to={linkTo} onClick={onItemClick}>
        <StyledListItemButton>
          <StyledListItemIcon>
            {icon}
          </StyledListItemIcon>
          <StyledListItemText primary={name} />
        </StyledListItemButton>
      </StyledNavLink>
    </>
  );
};

const SettingLeftSidebar: React.FC<{ onItemClick: () => void }> = ({ onItemClick }) => {
  const { t } = useTranslation();
  const loginUser = useSelector((state: RootState) => state.auth.loginUser);
  if (!loginUser) {
    return;
  }

  return (
    <List component="div" disablePadding>
      <ListItem
        icon={<Person />}
        // t:ユーザー情報
        name={t("setting:profile.pageTitle")}
        linkTo="/setting/profile"
        onItemClick={onItemClick} />
      <ListItem
        icon={<Language />}
        // t:言語設定
        name={t("setting:locale.pageTitle")}
        linkTo="/setting/locale"
        onItemClick={onItemClick} />
      <ListItem
        icon={<ControlCamera />}
        // t:操作設定
        name={t("setting:control.pageTitle")}
        linkTo="/setting/control"
        onItemClick={onItemClick} />
    </List>
  );
};

export default SettingLeftSidebar;
