import React from 'react';
import { useEditor, Editor, Content } from "@tiptap/react";
import Document from "@tiptap/extension-document";
import Paragraph from "@tiptap/extension-paragraph";
import HardBreak from '@tiptap/extension-hard-break';
import Text from "@tiptap/extension-text";
import Mention from '@tiptap/extension-mention'
import MessageFormPresenter from '../presenters/MessageFormPresenter';
import { getSuggestion } from './MessageForm/suggestion';
import Placeholder from '@tiptap/extension-placeholder';
import { useMediaQuery, useTheme } from '@mui/material';
import History from '@tiptap/extension-history';
import { useSelector } from 'react-redux';
import { RootState } from '@/store';
import { getCurrentMembership, getUiFlagValue } from '../../auth/auth.type';
import { isThreadDetailPage } from '@/lib/utils';
import { FileUpload } from '../../file/file.type';
import { useTranslation } from 'react-i18next';
import {
  UI_FLAG_QP_POST_SHORTCUT_TYPE,
  UI_FLAG_QP_POST_SHORTCUT_TYPE_VAL_CTRL_ENTER,
} from '../../setting/setting.constant';

interface ThreadMessageFormProps {
  value: object | undefined;
  onChange: (value: object | undefined) => void;
  onSubmit: () => void;
  onStop: () => void;
  onGenerating: boolean;
  selectedFile?: File | FileUpload;
  onSelectFile: (file: File | undefined) => void;
  // オプション
  hideSubmitButton?: boolean;
  disableGeneratingMessage?: boolean;
}

export const MessageFormContainer: React.FC<ThreadMessageFormProps> = ({
  value,
  onChange,
  onSubmit,
  onStop,
  onGenerating,
  selectedFile,
  onSelectFile,
  hideSubmitButton = false,
  disableGeneratingMessage = false,
}) => {
  const { t } = useTranslation();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('md'));
  const loginUser = useSelector((state: RootState) => state.auth.loginUser);
  const membership = getCurrentMembership(loginUser);

  // メッセージ投稿時のショートカットタイプを取得
  const postShortcutType = getUiFlagValue(
    loginUser,
    UI_FLAG_QP_POST_SHORTCUT_TYPE,
    UI_FLAG_QP_POST_SHORTCUT_TYPE_VAL_CTRL_ENTER
  );
  // Ctrl + Enterで投稿するかどうか
  const shouldPostByCtrlEnter =
    postShortcutType === UI_FLAG_QP_POST_SHORTCUT_TYPE_VAL_CTRL_ENTER;

  let aiModelCodes: string[] | undefined = undefined;
  if (membership) {
    aiModelCodes = membership.team.quota.usableAiCodes;
  }

  const onSubmitRef = React.useRef(onSubmit);
  React.useEffect(() => {
    onSubmitRef.current = onSubmit;
  }, [onSubmit]);

  const editor = useEditor({
    extensions: [
      Placeholder.configure({
        placeholder: () => {
          const isThreadUrl = isThreadDetailPage();
          let placeholder = '';

          if (isThreadUrl) {
            // t:AIに追加で指示する
            placeholder = t('thread:messageForm.placeholder.thread');
          } else {
            // t:クイックプロンプトなしでAIに指示する
            placeholder = t('thread:messageForm.placeholder.newThread');
          }

          if (!isMobile) {
            if (shouldPostByCtrlEnter) {
              // t: （Enterで改行 Ctrl+Enterで送信）
              placeholder += t('thread:messageForm.placeholder.noticeForCtrlEnterSubmit');
            } else {
              // t: (Ctrl+Enterで改行 Enterで送信)
              placeholder += t('thread:messageForm.placeholder.noticeForEnterSubmit');
            }
          }

          return placeholder;
        },
      }),
      Document,
      // HardBreakを拡張して、ショートカットをまとめて管理
      HardBreak.extend({
        addKeyboardShortcuts() {
          // 送信 or 改行を共通化したハンドラー
          const handleEnter = (shouldSubmit: boolean) => {
            if (shouldSubmit) {
              // 投稿
              onSubmitRef.current();
              return true;
            }
            // 改行
            if (!this.editor.can().setHardBreak()) {
              return false;
            }
            return this.editor.commands.setHardBreak();
          };

          return {
            // Enter の場合、Ctrl+Enterモードなら改行、そうでなければ投稿
            Enter: () => handleEnter(!shouldPostByCtrlEnter),
            // Shift+Enter は常に改行
            "Shift-Enter": () => handleEnter(false),
            // Ctrl+Enter / Meta+Enter は Ctrl+Enterモードなら投稿、そうでなければ改行
            "Control-Enter": () => handleEnter(shouldPostByCtrlEnter),
            "Meta-Enter": () => handleEnter(shouldPostByCtrlEnter),
          };
        },
      }),
      Paragraph,
      Text,
      Mention.configure({
        suggestion: getSuggestion(
          aiModelCodes,
          membership?.mentionedAiModelCodesAtLastThreadCreation,
        ),
      }),
      // undo/redoを使えるようにする
      History,
    ],
    content: value,
    onUpdate: ({ editor }) => {
      onChange(editor.getJSON());
    },
    editorProps: {
      handlePaste(view, event) {
        const text = event.clipboardData?.getData('text/plain');
        if (text) {
          const lines = text.split('\n');
          const nodes = lines.map((line) => ({
            type: 'paragraph',
            content: line ? [{ type: 'text', text: line }] : [],
          }));
          const fragment = view.state.schema.nodeFromJSON({
            type: 'doc',
            content: nodes,
          });
          const transaction = view.state.tr.replaceSelectionWith(fragment);
          view.dispatch(transaction);
          return true;
        }
        return false;
      },
    },
  }) as Editor;

  // valueのリセットを反映
  React.useEffect(() => {
    // setContentが走る度にカーソルの位置が末尾に移動してしまうので、
    // Tiptap内の値と親コンポーネントから渡された値が異なる場合のみsetContentするようにする
    if (editor && JSON.stringify(editor.getJSON()) === JSON.stringify(value)) {
      return;
    }
    editor?.commands.setContent(value as Content);
  }, [value, editor]);

  // メンションリストの更新
  React.useEffect(() => {
    if (!editor) return;
    const extensions = editor.options.extensions;
    editor.setOptions({
      extensions: extensions.map((ext) => {
        if (ext.name === 'mention') {
          return Mention.configure({
            suggestion: getSuggestion(
              aiModelCodes,
              membership?.mentionedAiModelCodesAtLastThreadCreation,
            ),
          });
        }
        return ext;
      }),
    });
  }, [loginUser, editor]);

  const teamConfig = membership?.team?.config;
  if (!(editor && membership && teamConfig)) {
    return null;
  }

  return (
    <MessageFormPresenter
      editor={editor}
      onSubmit={onSubmit}
      onStop={onStop}
      onGenerating={onGenerating}
      hideSubmitButton={hideSubmitButton}
      disableGeneratingMessage={disableGeneratingMessage}
      attachableFile={teamConfig.isFileAttachmentEnabled}
      selectedFile={selectedFile}
      onSelectFile={onSelectFile}
    />
  );
};

export default MessageFormContainer;
